<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Your {{ benefitsEmployerName(specialBenefits) }} Benefits</h1>
        <h2 class="subheader"></h2>
      </div>
    </div>
    <div v-if="!specialBenefits.benefitsDescriptionHtml && !specialBenefits.benefitsDescription" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">Description of benefits is not available</div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col benefits-container">
       <p v-if="specialBenefits.benefitsDescriptionHtml" class="detail-text" v-html="specialBenefits.benefitsDescriptionHtml"></p>
        <p v-if="!specialBenefits.benefitsDescriptionHtml" class="detail-text">{{specialBenefits.benefitsDescription}}</p>
      </div>
      <div class="col-md-1"></div>
    </div>
    <div class="row">
      <div class="col-xl-3"></div>
      <div v-if="specialBenefits.specialBenefitsInterest === false" class="col text-center">
        <div class="form-row mt-2">
          <button v-if="specialBenefits.affirmBenefitsCopy" type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">
            {{specialBenefits.affirmBenefitsCopy}}</button>
          <button v-if="!specialBenefits.affirmBenefitsCopy" type="button" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">
            Next</button>
        </div>
        <div class="form-row mt-2">
          <button v-if="specialBenefits.skipBenefitsCopy" type="submit" @click.prevent="skipBenefits()" class="btn btn-outline-primary btn-lg mx-auto" style="width: 80%">{{specialBenefits.skipBenefitsCopy}}</button>
          <button v-if="!specialBenefits.skipBenefitsCopy" type="submit" @click.prevent="skipBenefits()" class="btn btn-outline-primary btn-lg mx-auto" style="width: 80%">Skip</button>
        </div>
      </div>
      <div v-if="specialBenefits.specialBenefitsInterest === true" class="col text-center">
        <button type="submit" @click.prevent="next()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">Next</button>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>
</template>

<script>
  import api from '@/services/api'
  import AnalyticsMixin from '@/mixins/AnalyticsMixin'
  import ErrorsMixin from '@/mixins/ErrorsMixin'

  export default {
    name: 'SpecialBenefitsConfirm',
    mixins: [ErrorsMixin, AnalyticsMixin],

    data () {
      return {
        specialBenefits: {},
        hasBenefits: true,
        isSaving: false
      }
    },
    methods: {
      loadData () {
        api.get('/Patient/SpecialBenefits/').then(response => {
          this.specialBenefits = response.data
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.$store.logErrorResponse(this.$options.name, 'LoadError', error)
            this.setFromApiErrorResponse(error)
          }
        })
      },
      benefitsEmployerName (specialBenefits) {
        if (specialBenefits.insuranceEmployerName) {
          return specialBenefits.insuranceEmployerName
        }
        return specialBenefits.employerName
      },
      submit () {
        this.isSaving = true
        api.post('/Patient/SpecialBenefits/' + this.hasBenefits).then(response => {
          this.trackEvent(this.$options.name, 'Save', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('SpecialBenefits', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to save benefit response: ' + error.message, error)
          }
        })
      },
      skipBenefits () {
        this.hasBenefits = false
        api.post('/Patient/SpecialBenefits/' + this.hasBenefits).then(response => {
          this.trackEvent(this.$options.name, 'SkipBenefits', 'ResponseTime', response.config.requestDuration)
          this.$store.setModuleStatus('SpecialBenefits', true, () => {
            this.$router.push(this.$store.nextRoute())
          })
        }).catch(error => {
          this.isSaving = false
          if (!this.$store.canHandleNetworkError(error)) {
            this.$log.error('Error attempting to save benefit response: ' + error.message, error)
          }
        })
      },
      next () {
        this.$store.setModuleStatus('SpecialBenefits', true, () => {
          this.$router.push(this.$store.nextRoute())
        })
      }
    },
    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
.benefits-container {
  display: flex;
  flex-basis: auto;
  justify-content: space-around;
}
</style>

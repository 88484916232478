<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="header">Enhanced benefits are available</h1>
        <h2 class="subheader">
          {{ benefitsEmployerName(specialBenefits) }} and {{orgName}} have partnered to offer enhanced benefits.</h2>
      </div>
    </div>
    <div v-if="generalErrorMessage" class="row">
      <div class="col-xl-3"></div>
      <div class="col">
        <div class="alert alert-danger" role="alert">
          {{ generalErrorMessage }}
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="d-flex flex-wrap align-items-start justify-content-center">
          <div class="employer">
          <div class="img-container">
            <span class="helper"></span>
            <img :src="specialBenefits.logoUrl" :alt="specialBenefits.name">
          </div>
          </div>
          </div>
      </div>
      <div class="col-xl-3"></div>
      <div class="col text-center">
        <div class="form-row mt-2">
          <button type="submit" @click.prevent="submit()" class="btn btn-primary btn-lg mx-auto" style="width: 80%">Review Benefits</button>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
  </div>

</template>

<script>
  import api from '../../services/api'
  import ErrorsMixin from '../../mixins/ErrorsMixin'
  import OptionsMixin from '../../mixins/OptionsMixin'
  import AnalyticsMixin from '../../mixins/AnalyticsMixin'

  export default {
    name: 'SpecialBenefitsEmployer',

    mixins: [ErrorsMixin, OptionsMixin, AnalyticsMixin],

    props: {
      id: {
        default: 0
      }
    },

    data () {
      return {
        specialBenefits: {}
      }
    },
    computed: {
      orgName () {
        return this.$store.orgName
      }
    },
    methods: {
      validate () {
        this.clearAllErrors()

        return !this.hasErrors()
      },

      benefitsEmployerName (specialBenefits) {
        if (specialBenefits.insuranceEmployerName) {
          return specialBenefits.insuranceEmployerName
        }
        return specialBenefits.employerName
      },

      loadData () {
        api.get('/Patient/SpecialBenefits/').then(response => {
          this.trackEvent(this.$options.name, 'Load', 'ResponseTime', response.config.requestDuration)
          this.specialBenefits = response.data
          if (!this.specialBenefits.benefitsDescription) {
            this.$store.setModuleStatus('SpecialBenefits', true, () => {
              this.$router.push(this.$store.nextRoute())
            })
          }
        }).catch(error => {
          if (!this.$store.canHandleNetworkError(error)) {
            this.trackEvent(this.$options.name, 'LoadError', 'ResponseTime', error.config.requestDuration)
            this.setFromApiErrorResponse(error)
          }
        })
      },

      submit () {
        this.$router.push('special-benefits-confirm')
      }
    },

    created () {
      this.loadData()
    }
  }
</script>

<style scoped>
.employer {
  display: inline-block;
  height: 180px;
  width: 180px;
  margin: 1em;
  background-color: white;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.img-container {
  margin: 2px;
  width: 174px;
  height: 174px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.img-container img {
  max-width: 100%;
  max-height: 100%;
}
</style>
